import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './IconArrowRight.styles'

interface Props extends PropsWithClassName {}

const IconArrowRight: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'icon-arrow-right-root'}
      width="13"
      height="20"
      viewBox="0 0 13 20"
      transform="scale(-1,1)"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Vor</title>
      <desc>Schaltfläche, um vorwärts zu gehen</desc>
      <path
        d="M10.5684 17.9597L2.35765 9.74901L10.5684 1.53829"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Root>
  )
}

export { IconArrowRight }
