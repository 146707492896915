import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoKubernetes.styles'

interface Props extends PropsWithClassName {}

const LogoKubernetes: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'logo-kubernetes-root'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 74.5 69.1"
      {...props}
    >
      <g>
        <path
          d="M34.8,24.5c0.5,0,0.9-0.4,0.9-0.9h0l0.3-5.2c-0.4,0-0.7,0.1-1.1,0.2c-1.9,0.4-3.6,1.4-5,2.7l4.3,3.1
		C34.5,24.4,34.6,24.5,34.8,24.5z"
        />
        <path d="M32.5,28c0.3-0.4,0.3-0.9-0.1-1.2v0l-3.9-3.5v0c-1.2,1.9-1.7,4.2-1.5,6.5l5.1-1.5C32.2,28.3,32.3,28.2,32.5,28z" />
        <polygon points="35.8,29.8 37.3,30.5 38.7,29.8 39.1,28.3 38.1,27 36.5,27 35.5,28.3 	" />
        <path d="M39,24.2c0.3,0.4,0.9,0.4,1.2,0.1l4.3-3c-1.6-1.6-3.7-2.6-6-2.9v0l0.3,5.2C38.8,23.8,38.9,24,39,24.2z" />
        <path
          d="M32.9,31.3L32.9,31.3c-0.1,0-0.2,0-0.2,0l-5.2,0.9c0.8,2.1,2.2,4,4.1,5.2l2-4.8c0-0.1,0.1-0.2,0.1-0.3
		C33.8,31.7,33.4,31.3,32.9,31.3z"
        />
        <path
          d="M61.2,32.9L57.1,15c-0.2-0.9-0.9-1.7-1.7-2.2l-16.7-8c-0.5-0.2-1-0.3-1.5-0.3c-0.4,0-0.9,0.1-1.2,0.3l-16.7,8
		c-0.9,0.4-1.5,1.2-1.7,2.2l-4.1,17.9c-0.2,0.8,0,1.7,0.4,2.4c0.1,0.1,0.1,0.2,0.2,0.3L25.5,50c0.6,0.7,1.5,1.2,2.5,1.2h18.6
		c1,0,1.9-0.4,2.5-1.2l11.6-14.4C61.2,34.9,61.5,33.9,61.2,32.9z M54.8,32.7c-0.2,0.5-0.7,0.8-1.3,0.6h0c-0.1,0-0.2,0-0.3-0.1
		c-0.3-0.1-0.6-0.2-0.9-0.3c-0.6-0.2-1.2-0.4-1.7-0.5c-0.2,0-0.4,0.1-0.6,0.2l-0.4-0.1c-1,3-3,5.6-5.7,7.2c0,0.2,0.1,0.3,0.2,0.4
		c-0.1,0.2-0.2,0.4-0.1,0.6c0.2,0.6,0.5,1.1,0.9,1.6c0.2,0.2,0.4,0.5,0.5,0.8l0.1,0.3c0.2,0.3,0.2,0.7,0,1c-0.1,0.2-0.3,0.3-0.5,0.4
		c-0.5,0.2-1.1-0.1-1.3-0.6l-0.1-0.3c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2-0.6-0.4-1.2-0.7-1.7c-0.1-0.2-0.3-0.3-0.5-0.3l-0.2-0.4
		c-3,1.1-6.3,1.1-9.2,0l-0.2,0.4c-0.2,0-0.3,0.1-0.4,0.2c-0.4,0.4-0.5,1.1-0.8,1.8c-0.1,0.3-0.2,0.6-0.3,0.9l-0.1,0.3
		C30.7,44.8,30.4,45,30,45c-0.2,0-0.4,0-0.6-0.1c-0.5-0.3-0.6-0.9-0.4-1.4l0.1-0.3c0.2-0.3,0.3-0.5,0.5-0.8c0.4-0.5,0.7-1.1,0.9-1.6
		c0-0.2,0-0.4-0.1-0.6l0.2-0.4c-2.7-1.6-4.8-4.2-5.8-7.2l-0.4,0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.6,0.1-1.2,0.3-1.7,0.5
		c-0.3,0.1-0.6,0.2-0.9,0.3l-0.3,0h0c-0.4,0.1-0.8,0-1-0.2c-0.2-0.2-0.2-0.4-0.3-0.6c-0.1-0.5,0.3-1,0.9-1.1h0l0.3-0.1
		c0.3,0,0.6-0.1,1-0.1c0.6,0,1.2-0.1,1.8-0.3c0.2-0.1,0.3-0.3,0.4-0.4l0.4-0.1c-0.5-3.2,0.3-6.4,2-9l-0.3-0.3c0-0.2-0.1-0.4-0.2-0.6
		c-0.5-0.4-1-0.7-1.5-1c-0.3-0.2-0.6-0.3-0.8-0.5l-0.2-0.2c-0.5-0.3-0.6-1-0.2-1.5c0.2-0.2,0.5-0.4,0.8-0.3c0.3,0,0.5,0.1,0.7,0.3
		l0.2,0.2c0.2,0.2,0.4,0.5,0.7,0.7c0.4,0.4,0.9,0.9,1.4,1.2c0.2,0.1,0.4,0.1,0.6,0.1l0.4,0.3c1.8-1.9,4-3.2,6.5-3.7
		c0.6-0.1,1.2-0.2,1.8-0.3l0-0.4c0.2-0.1,0.3-0.3,0.3-0.5c0-0.6,0-1.2-0.1-1.8c-0.1-0.3-0.1-0.6-0.1-1v-0.3c0-0.6,0.4-1.1,1-1.1l0,0
		h0c0.6,0,1,0.5,1,1.1V12c0,0.3-0.1,0.6-0.1,1C38,13.5,38,14.2,38,14.8c0,0.2,0.2,0.4,0.3,0.5c0,0.1,0,0.3,0,0.4
		c3.2,0.3,6.1,1.7,8.3,4l0.4-0.3c0.2,0,0.4,0,0.6-0.1c0.5-0.4,0.9-0.8,1.3-1.2c0.2-0.2,0.4-0.5,0.7-0.7l0.2-0.2
		c0.3-0.3,0.7-0.4,1-0.2c0.2,0.1,0.4,0.2,0.5,0.4c0.3,0.5,0.2,1.1-0.3,1.4L50.9,19c-0.3,0.2-0.5,0.3-0.8,0.5c-0.5,0.3-1,0.7-1.5,1
		c-0.1,0.2-0.2,0.3-0.2,0.6L48,21.4c1.8,2.6,2.5,5.8,2.1,9l0.4,0.1c0.1,0.2,0.3,0.3,0.4,0.4c0.6,0.2,1.2,0.2,1.8,0.3
		c0.3,0,0.6,0,1,0.1l0.3,0.1c0.4,0,0.7,0.3,0.8,0.7C54.9,32.3,54.9,32.5,54.8,32.7z"
        />
        <path
          d="M38.1,33.9c-0.2-0.3-0.5-0.5-0.8-0.5l0,0c-0.3,0-0.6,0.2-0.7,0.4L34,38.5c1.8,0.6,3.8,0.7,5.7,0.3c0.3-0.1,0.7-0.2,1-0.3
		L38.1,33.9L38.1,33.9z"
        />
        <path d="M46.1,23.3l-3.9,3.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.5,0.2,1,0.6,1.1l5.1,1.5C47.8,27.5,47.3,25.2,46.1,23.3L46.1,23.3z" />
        <path
          d="M41.6,31.3L41.6,31.3c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-0.7,0.7-0.5,1.2l2,4.9c1.9-1.2,3.4-3.1,4.2-5.3l-5.2-0.9
		C41.8,31.3,41.6,31.3,41.6,31.3z"
        />
      </g>
      <path
        d="M2.1,64.2l0.6-0.7C3,63.3,3.2,63,3.4,62.8l0.6-0.7c0.2-0.2,0.3-0.4,0.5-0.6h2.5c-0.5,0.6-1,1.1-1.4,1.6s-1,1-1.5,1.6
	c0.3,0.3,0.6,0.6,0.9,0.9c0.3,0.3,0.6,0.7,0.9,1.1c0.3,0.4,0.5,0.7,0.8,1.1c0.2,0.4,0.4,0.7,0.6,1H4.6c-0.1-0.2-0.3-0.5-0.5-0.8
	c-0.2-0.3-0.4-0.6-0.6-0.9C3.2,67,3,66.7,2.8,66.4c-0.2-0.2-0.5-0.5-0.7-0.7v3.2H0V58.4l2.1-0.3V64.2L2.1,64.2L2.1,64.2z M14.2,68.7
	c-0.4,0.1-0.9,0.2-1.4,0.3c-0.6,0.1-1.2,0.1-1.8,0.1c-0.5,0-1.1-0.1-1.6-0.2c-0.4-0.2-0.7-0.4-1-0.7c-0.2-0.3-0.4-0.7-0.5-1.1
	c-0.1-0.4-0.2-0.9-0.2-1.4v-4.1H10v3.8c0,0.7,0.1,1.2,0.3,1.5c0.2,0.3,0.5,0.4,1,0.4c0.1,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4-0.1v-5.7
	h2.1L14.2,68.7L14.2,68.7z M23.1,65.3c0,0.5-0.1,1.1-0.3,1.6c-0.2,0.4-0.4,0.9-0.8,1.2c-0.3,0.3-0.8,0.6-1.2,0.8
	c-0.5,0.2-1.1,0.3-1.6,0.3c-0.2,0-0.5,0-0.8,0c-0.3,0-0.5,0-0.8-0.1c-0.3,0-0.5-0.1-0.8-0.1c-0.2,0-0.5-0.1-0.6-0.2V58.4l2.1-0.3
	v3.7c0.2-0.1,0.5-0.2,0.7-0.2c0.3-0.1,0.5-0.1,0.8-0.1c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.4,1,0.8c0.3,0.4,0.5,0.8,0.6,1.2
	C23,64.2,23.1,64.7,23.1,65.3L23.1,65.3z M20.9,65.2c0-1.3-0.5-2-1.5-2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.5,0.2v3.8
	c0.1,0,0.2,0,0.4,0c0.2,0,0.3,0,0.5,0c0.5,0,1-0.2,1.3-0.6C20.8,66.3,21,65.8,20.9,65.2L20.9,65.2L20.9,65.2z M24.3,65.3
	c0-0.6,0.1-1.2,0.3-1.7c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.3,0.7-0.6,1.1-0.7c0.4-0.2,0.9-0.2,1.3-0.2c1,0,1.9,0.3,2.5,1
	c0.6,0.6,0.9,1.6,0.9,2.8c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0,0.4h-4.7c0,0.4,0.3,0.8,0.6,1c0.4,0.3,0.9,0.4,1.4,0.4
	c0.4,0,0.8,0,1.1-0.1c0.3,0,0.6-0.1,0.9-0.3l0.3,1.7c-0.1,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.5,0.1-0.7,0.1
	c-0.3,0-0.5,0-0.8,0c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.4-1.2-0.8c-0.3-0.3-0.6-0.8-0.7-1.2C24.4,66.4,24.3,65.8,24.3,65.3
	L24.3,65.3L24.3,65.3z M29.2,64.5c0-0.2,0-0.3-0.1-0.5c0-0.2-0.1-0.3-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1
	c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.5s-0.1,0.3-0.1,0.5C26.5,64.5,29.2,64.5,29.2,64.5z
	 M37.3,63.4c-0.2,0-0.4-0.1-0.7-0.2c-0.3,0-0.5-0.1-0.8-0.1c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4,0.1v5.6h-2.1v-7
	c0.4-0.2,0.9-0.3,1.3-0.4c0.6-0.1,1.1-0.2,1.7-0.2c0.1,0,0.2,0,0.4,0s0.3,0,0.5,0.1s0.3,0,0.5,0.1c0.1,0,0.3,0.1,0.4,0.1L37.3,63.4
	L37.3,63.4z M38.7,61.8c0.5-0.1,0.9-0.2,1.4-0.3c0.6-0.1,1.2-0.1,1.8-0.1c0.5,0,1.1,0.1,1.6,0.2c0.4,0.1,0.7,0.4,1,0.7
	c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.5,0.2,0.9,0.2,1.4v4.2H43V65c0-0.7-0.1-1.2-0.3-1.4s-0.5-0.4-1-0.4c-0.1,0-0.3,0-0.5,0
	c-0.2,0-0.3,0-0.5,0v5.7h-2.1L38.7,61.8L38.7,61.8L38.7,61.8z M46.6,65.3c0-0.6,0.1-1.2,0.3-1.7c0.2-0.5,0.5-0.9,0.8-1.2
	c0.3-0.3,0.7-0.6,1.1-0.7c0.4-0.2,0.9-0.2,1.3-0.2c1,0,1.9,0.3,2.5,1c0.6,0.6,0.9,1.6,0.9,2.8c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0,0.4
	h-4.7c0,0.4,0.3,0.8,0.6,1c0.4,0.3,0.9,0.4,1.4,0.4c0.4,0,0.8,0,1.1-0.1c0.3,0,0.6-0.1,0.9-0.3l0.3,1.7c-0.1,0.1-0.3,0.1-0.5,0.2
	C52.5,68.9,52.2,69,52,69c-0.2,0-0.5,0.1-0.7,0.1c-0.3,0-0.5,0-0.8,0c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.4-1.2-0.8
	c-0.3-0.3-0.6-0.8-0.7-1.2C46.7,66.3,46.6,65.8,46.6,65.3L46.6,65.3L46.6,65.3z M51.5,64.5c0-0.2,0-0.3-0.1-0.5
	c0-0.2-0.1-0.3-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.3
	c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.3-0.1,0.5C48.8,64.5,51.5,64.5,51.5,64.5z M55.1,59.7l2.1-0.3v2.2h2.5v1.7h-2.5v2.6
	c0,0.4,0.1,0.7,0.2,1.1c0.2,0.3,0.5,0.4,0.9,0.4c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.7-0.2l0.3,1.6c-0.3,0.1-0.5,0.2-0.8,0.3
	c-0.4,0.1-0.8,0.1-1.1,0.1c-0.5,0-1-0.1-1.4-0.2c-0.3-0.1-0.7-0.4-0.9-0.7c-0.2-0.3-0.4-0.6-0.5-1c-0.1-0.4-0.1-0.9-0.1-1.3
	L55.1,59.7C55.1,59.7,55.1,59.7,55.1,59.7z M60.8,65.3c0-0.6,0.1-1.2,0.3-1.7c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.3,0.7-0.6,1.1-0.7
	c0.4-0.2,0.9-0.2,1.3-0.2c1.1,0,1.9,0.3,2.5,1c0.6,0.6,0.9,1.6,0.9,2.8c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0,0.4h-4.7
	c0,0.4,0.3,0.8,0.6,1c0.4,0.3,0.9,0.4,1.4,0.4c0.4,0,0.8,0,1.1-0.1c0.3-0.1,0.6-0.1,0.9-0.3l0.3,1.7c-0.1,0.1-0.3,0.1-0.4,0.2
	c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.3,0-0.5,0-0.8,0c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.5-1.2-0.8
	c-0.3-0.3-0.6-0.8-0.7-1.2C60.9,66.3,60.8,65.8,60.8,65.3L60.8,65.3L60.8,65.3z M65.7,64.5c0-0.2,0-0.3-0.1-0.5
	c-0.1-0.2-0.1-0.3-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.3
	c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.3-0.1,0.5L65.7,64.5L65.7,64.5z M71.4,67.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.2,0.2-0.4
	c0-0.2-0.1-0.3-0.3-0.4c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.2-0.9-0.4c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.4-0.4-0.7
	c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.6,0.3-1.2,0.8-1.6c0.5-0.4,1.2-0.6,2.1-0.6c0.4,0,0.9,0,1.3,0.1c0.3,0.1,0.7,0.2,1,0.3l-0.4,1.6
	c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.6-0.1-1-0.1c-0.7,0-1,0.2-1,0.5c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.2,0.2
	c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.6,0.2c0.4,0.1,0.8,0.3,1.1,0.5c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.3,0.4,0.4,0.6
	c0.1,0.3,0.1,0.5,0.1,0.8c0,0.7-0.3,1.3-0.8,1.7s-1.3,0.6-2.3,0.6c-0.6,0-1.1-0.1-1.6-0.2c-0.3-0.1-0.6-0.2-0.9-0.3l0.3-1.7
	c0.3,0.1,0.7,0.2,1.1,0.3C70.7,67.4,71,67.4,71.4,67.4L71.4,67.4z"
      />
    </Root>
  )
}

export { LogoKubernetes }
