import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoTypescript.styles'

interface Props extends PropsWithClassName {}

const LogoTypescript: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'logo-typescript-root'}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 91 91"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_6468_330)">
        <path
          d="M78.7842 0.642578H12.7203C6.12321 0.642578 0.773438 5.99235 0.773438 12.5894V78.6533C0.773438 85.2504 6.12321 90.6002 12.7203 90.6002H78.7842C85.3812 90.6002 90.731 85.2504 90.731 78.6533V12.5852C90.7268 5.9881 85.3812 0.642578 78.7842 0.642578ZM52.2729 49.4905H40.5637V82.7601H32.2823V49.4905H20.5731L20.6028 45.808L20.6452 42.1383L36.3636 42.0959C45.0013 42.0831 52.1287 42.1086 52.1711 42.168C52.2263 42.2104 52.2729 43.882 52.2729 45.8802V49.4905ZM82.7467 76.1036C81.3169 79.8455 77.7787 82.3867 72.7938 83.2267C71.2538 83.4982 67.5968 83.4558 65.9422 83.1546C62.3318 82.514 58.9039 80.7279 56.7912 78.3861C55.9639 77.4739 54.3475 75.0896 54.4493 74.9157C54.4917 74.8605 54.8651 74.6315 55.2766 74.3854C55.6754 74.1563 57.19 73.2866 58.6197 72.4593L61.2033 70.9617L61.7464 71.7593C62.5015 72.9175 64.1604 74.4999 65.1573 75.0303C68.0253 76.5448 71.9665 76.3285 73.9096 74.589C74.7369 73.8339 75.0805 73.049 75.0805 71.8908C75.0805 70.8472 74.9532 70.3932 74.4102 69.6084C73.7102 68.6071 72.2847 67.7671 68.2289 66.0107C63.5876 64.0125 61.5894 62.7695 59.7609 60.801C58.7045 59.6597 57.7033 57.8312 57.2918 56.3039C56.9481 55.0312 56.8633 51.8493 57.1348 50.5639C58.0894 46.0796 61.4749 42.9529 66.358 42.0237C67.9446 41.7225 71.6271 41.8371 73.1841 42.2231C75.4666 42.7959 77.2102 43.8098 78.8096 45.4644C79.6369 46.3511 80.8672 47.9632 80.9648 48.3493C80.9945 48.4638 77.083 51.0899 74.7114 52.5621C74.6266 52.6172 74.2829 52.2481 73.8969 51.6754C72.7387 49.9911 71.5253 49.2614 69.6713 49.1341C66.9434 48.9475 65.187 50.3772 65.204 52.7615C65.204 53.4615 65.3058 53.8772 65.5901 54.4457C66.1883 55.6888 67.304 56.4312 70.7998 57.9458C77.2399 60.7161 79.9933 62.5446 81.7073 65.141C83.6206 68.0386 84.0449 72.6629 82.7467 76.1036Z"
          fill="white"
          stroke="white"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_6468_330">
          <rect
            width="90"
            height="90"
            fill="white"
            transform="translate(0.753906 0.621094)"
          />
        </clipPath>
      </defs>
    </Root>
  )
}

export { LogoTypescript }
