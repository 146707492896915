import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoSulu.styles'

interface Props extends PropsWithClassName {}

const LogoSulu: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'logo-sulu-root'}
      width="129"
      height="150"
      fill="none"
      viewBox="0 -15 100 66.2"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="logo-sulu-title logo-sulu-desc"
      role="img"
    >
      <title id="logo-sulu-title">Logo Sulu</title>
      <desc id="logo-sulu-desc">
        The ideal combination of PHP developer experience and agency platform.
        Deliver complex sites and great user experiences for the content teams
        who work on your site every day.
      </desc>
      <g id="b">
        <g>
          <path
            id="f"
            d="m10.78,60.51c0-.88-.22-1.58-.77-2.05-.5-.44-1.16-.69-2.14-.83l-2.33-.33c-1.58-.24-2.78-.73-3.71-1.59-.98-.9-1.45-2.17-1.45-3.77,0-3.43,2.48-5.75,6.56-5.75,1.86,0,2.99.3,4.62,1.16.69.36,1.2.87,1.2,1.51,0,.32-.11.64-.34.91-.22.26-.51.43-.91.43-.32,0-.8-.18-1.2-.43-1.17-.72-2.09-.97-3.47-.97-2.31,0-3.56,1.26-3.56,3.05,0,.72.19,1.38.72,1.82.5.44,1.07.72,2.15.88l2.34.36c1.79.28,2.78.69,3.64,1.47,1.05.97,1.6,2.32,1.6,4.04,0,3.62-2.92,5.78-7.06,5.78-2.36,0-3.83-.46-5.28-1.33-1.02-.61-1.39-1.02-1.39-1.74,0-.33.15-.72.41-.98.25-.25.55-.36.87-.36.39,0,.73.12,1.27.5,1.32.91,2.48,1.26,4.18,1.26,2.59,0,4.05-1.11,4.05-3.04"
          />
          <path
            id="g"
            d="m25.65,59.4v-11.12c0-.73.01-.97.22-1.35.22-.4.67-.68,1.27-.68s1.05.28,1.27.68c.21.39.22.62.22,1.35v10.99c0,2.64,1.6,4.28,4.05,4.28s4.05-1.64,4.05-4.28v-10.99c0-.73.01-.97.22-1.35.22-.4.68-.68,1.27-.68s1.05.28,1.27.68c.21.39.22.62.22,1.35v11.12c0,4.06-3.03,6.8-7.03,6.8s-7.03-2.74-7.03-6.8"
          />
          <path
            id="h"
            d="m53.19,65.61c-.43-.43-.43-.95-.43-1.4v-15.93c0-.73.01-.97.22-1.35.22-.4.68-.68,1.27-.68s1.05.28,1.27.68c.21.39.22.62.22,1.35v15.1h7.74c.63,0,.91.03,1.21.19.36.21.61.61.61,1.13s-.25.93-.61,1.13c-.3.17-.58.19-1.21.19h-8.9c-.44,0-.96,0-1.39-.43"
          />
          <g id="i">
            <g>
              <path
                id="m"
                d="m76.5,59.4v-11.12c0-.73.01-.97.22-1.35.22-.4.67-.68,1.27-.68s1.05.28,1.27.68c.21.39.22.62.22,1.35v10.99c0,2.64,1.6,4.28,4.05,4.28s4.05-1.64,4.05-4.28v-10.99c0-.73.01-.97.22-1.35.22-.4.68-.68,1.27-.68s1.05.28,1.27.68c.21.39.22.62.22,1.35v11.12c0,4.06-3.03,6.8-7.03,6.8s-7.03-2.74-7.03-6.8"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="n">
        <path
          id="o"
          d="m68.23,18.89c.45-.67.22-1.45-.45-1.89l-10.46-7.24c-.67-.45-1.22-1.34-1.34-2.12l-.78-6.68c-.11-.78-.78-1.11-1.45-.89l-28.93,9.13c-.78.22-1.11,1-.89,1.67l5.56,15.37c.22.67,1.11,1.45,1.78,1.56l26.26,5.35c.78.11,1.67-.22,2.11-.89l8.57-13.36h0Zm-23.81-6.79c-.45.56-1.45,1-2.23.78l-12.24-2.45c-.78-.11-.78-.45,0-.67l20.47-6.46c.78-.22,1,.11.56.67l-6.56,8.13h0Zm8.35-7.57c.45-.56.89-.45,1,.22l2.45,22.72c.11.78-.22.89-.67.22l-9.46-12.36c-.45-.56-.45-1.56,0-2.12l6.68-8.69h0Zm-25.93,8.24c-.22-.67.11-1.11.89-1l14.35,3.01c.78.11,1.78.78,2.23,1.34l10.35,13.48c.45.56.22,1-.56.78l-21.25-4.23c-.78-.11-1.56-.89-1.78-1.56l-4.23-11.81h0Zm29.82.11c-.11-.78.33-1,1-.56l7.57,5.23c.67.45.78,1.34.45,1.89l-6.34,9.8c-.45.67-.78.56-.89-.22l-1.78-16.15h0Z"
        />
      </g>
    </Root>
  )
}

export { LogoSulu }
