import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoNextjs.styles'

interface Props extends PropsWithClassName {}

const LogoNextjs: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'logo-nextjs-root'}
      viewBox="0 0 255.5 247.8"
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="100"
      {...props}
    >
      <path d="m120.47,2.15c-.52.05-2.19.21-3.69.33C82.16,5.6,49.73,24.28,29.19,52.99c-11.44,15.96-18.75,34.07-21.52,53.25-.98,6.7-1.1,8.67-1.1,17.75s.12,11.06,1.1,17.75c6.62,45.77,39.19,84.23,83.37,98.47,7.91,2.55,16.25,4.29,25.73,5.34,3.69.41,19.66.41,23.35,0,16.37-1.81,30.24-5.86,43.91-12.84,2.1-1.07,2.5-1.36,2.22-1.6-.19-.14-9.13-12.13-19.85-26.61l-19.49-26.33-24.42-36.14c-13.44-19.87-24.49-36.12-24.59-36.12-.1-.02-.19,16.04-.24,35.64-.07,34.33-.1,35.72-.52,36.53-.62,1.17-1.1,1.64-2.1,2.17-.76.38-1.43.45-5.03.45h-4.12l-1.1-.69c-.71-.45-1.24-1.05-1.6-1.74l-.5-1.07.05-47.77.07-47.8.74-.93c.38-.5,1.19-1.14,1.76-1.45.98-.48,1.36-.52,5.48-.52,4.86,0,5.67.19,6.93,1.57.36.38,13.58,20.3,29.4,44.29,15.82,23.99,37.46,56.75,48.08,72.84l19.3,29.23.98-.64c8.65-5.62,17.8-13.63,25.04-21.97,15.42-17.7,25.35-39.29,28.69-62.31.98-6.7,1.1-8.67,1.1-17.75s-.12-11.06-1.1-17.75c-6.62-45.77-39.19-84.23-83.37-98.47-7.79-2.53-16.08-4.26-25.38-5.31-2.29-.24-18.04-.5-20.01-.31h0Zm49.89,73.72c1.14.57,2.07,1.67,2.41,2.81.19.62.24,13.87.19,43.72l-.07,42.84-7.55-11.58-7.58-11.58v-31.14c0-20.13.1-31.45.24-32,.38-1.33,1.22-2.38,2.36-3,.98-.5,1.33-.55,5.08-.55,3.53,0,4.15.05,4.93.48h0Z" />
    </Root>
  )
}

export { LogoNextjs }
