import Link from 'next/link'
import styled from 'styled-components'

import { EyebrowH2 } from '@Components/atoms/Eyebrow/Eyebrow.styles'

import { defaultTheme } from '@Assets/styles/theme/theme'

import { TechnologiesBackground } from './Technologies'

interface RootProps {
  background: TechnologiesBackground
}

export const getBackgroundColor = (
  props: TechnologiesBackground | undefined
) => {
  switch (props) {
    case TechnologiesBackground.white:
      return defaultTheme.palette.default.white
    case TechnologiesBackground.bluedeep:
      return defaultTheme.palette.default.blueDeep
    default:
      return 'transparent'
  }
}

export const getTextColor = (props: TechnologiesBackground | undefined) => {
  switch (props) {
    case TechnologiesBackground.white:
      return defaultTheme.palette.default.greyMiddle
    case TechnologiesBackground.bluedeep:
      return defaultTheme.palette.default.white
    default:
      return defaultTheme.palette.default.white
  }
}

export const Root = styled.section<RootProps>`
  padding: ${(props) => props.theme.padding.default} 0;
  svg {
    width: 86%;
    height: auto;
    fill: ${(props) => getTextColor(props.background)};
    path {
      fill: ${(props) => getTextColor(props.background)};
    }
  }

  background: ${(props) => getBackgroundColor(props.background)};
  color: ${(props) => getTextColor(props.background)};
`

export const StyledEyebrow = styled(EyebrowH2)<RootProps>`
  color: ${(props) => getTextColor(props.background)};
  text-transform: uppercase;
  z-index: 20;
`

export const StyledLink = styled(Link)<RootProps>`
  position: absolute;
  bottom: 1.25rem;
  left: 0;
  color: ${(props) => getTextColor(props.background)};
  text-align: center;
  width: 100%;
  margin: 0;
  transition: all 300ms ease-in-out;
  svg {
    path {
      fill: ${(props) => getTextColor(props.background)};
    }
  }
`

export const StyledLogosWrapper = styled.div`
  @media screen and (max-width: ${(props) => props.theme.device.tablet}) {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
`
export const StyledLogoWrapper = styled.div`
  @media screen and (max-width: ${(props) => props.theme.device.desktop}) {
    margin-bottom: 1.875rem;
    min-width: 3rem;
    max-width: 6rem;
  }
`
