import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoGit.styles'

interface Props extends PropsWithClassName {}

const LogoGit: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'logo-git-root'}
      width="100%"
      height="100%"
      viewBox="0 0 1000 1000"
      {...props}
    >
      <path d="M298.2 627.9c79.4 5.4 96.4 3.5 96.4 19.5 0 32.7-107.2 32.8-107.2 2.5 0-8.3 5.4-15.3 10.8-22zm45.7-190.7c-52.8 0-55.2 72.7-1.2 72.7 53.1 0 51.8-72.7 1.2-72.7zm522.4-225v575.6c0 43.3-35.1 78.5-78.5 78.5H212.2c-43.3 0-78.5-35.1-78.5-78.5V212.2c0-43.3 35.1-78.5 78.5-78.5h575.6c43.4 0 78.5 35.1 78.5 78.5zM495.1 325.5c0 23.7 13.7 37.4 37.4 37.4 24.1 0 37.8-13.7 37.8-37.4S556.6 289 532.5 289c-23.7.1-37.4 12.9-37.4 36.5zm-35.7 74.8h-81c-40.9-10.7-133.4-7.9-133.4 76.4 0 30.7 15.4 52.3 35.7 62.3-25.7 23.5-37.8 34.6-37.8 50.3 0 11.2 4.6 21.6 18.3 27.4-14.6 13.7-22.9 23.7-22.9 42.4 0 32.8 28.7 51.9 103.9 51.9 72.3 0 114.3-27 114.3-74.8 0-60-46.2-57.8-155-64.4l13.7-22c27.8 7.8 121.3 10.2 121.3-69.4 0-19.1-7.9-32.4-15.4-42l38.2-2.9.1-35.2zm137.9 179.6L576 577c-6.2-.8-6.7-1.6-6.7-8.3V396.2h-86l-4.6 33.6c25.8 9.1 27.8 7.9 27.8 16.6v122.2c0 9.2-.5 7.5-27.8 11.2v32.8h118.4l.2-32.7zm164.3 16.6l-11.2-36.6c-66.9 25.1-61.9-20.3-61.9-27.4v-99.3h61.9v-41.6h-58.6c-4.7 0-3.3 4.1-3.3-63.2H649c-4.6 45.3-19.1 63.6-55.6 67.7v37c33.5 0 32.4-1.4 32.4 4.2v108.9c0 47 18.7 66.9 68.1 66.9 23.7.1 49.9-7.8 67.7-16.6z" />
    </Root>
  )
}

export { LogoGit }
