import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoPhpCircle.styles'

interface Props extends PropsWithClassName {}

const LogoPhpCircle = (props: PropsWithChildren<Props>): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'LogoPhpCircle-root'}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 57 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.0969 14.8873C56.0969 22.8677 43.6896 29.337 28.3844 29.337C13.0792 29.337 0.671875 22.8677 0.671875 14.8873C0.671875 6.90688 13.0792 0.4375 28.3844 0.4375C43.6896 0.4375 56.0969 6.90688 56.0969 14.8873ZM11.4902 8.04328L8.45312 23.4438H12.0596L12.8189 19.4511H16.2351C19.4621 19.6413 23.0684 16.9795 23.0684 12.4164C23.0684 9.75435 21.5499 8.23347 18.513 8.04328H11.4902ZM14.5271 10.8953L13.578 16.5993H13.5782H15.8558C17.564 16.4091 19.0826 15.8387 19.2723 12.6064C19.2723 11.4656 18.3233 10.8953 16.9946 10.8953H14.5271ZM25.3997 4.05078L22.3628 19.4511H25.9694L27.6776 10.8953H30.5248C31.8533 10.8953 31.8533 11.4656 31.6636 12.4164L30.335 19.4511H34.1311L35.4598 11.6558C35.8393 9.75435 34.7005 8.23347 31.6634 8.04328H28.247L29.0063 4.05078H25.3997ZM36.9249 8.04328L33.888 23.4438H37.4943L38.2536 19.4511H41.6702C44.897 19.6413 48.5035 16.9795 48.5035 12.4164C48.5035 9.75435 46.985 8.23347 43.9478 8.04328H36.9249ZM39.9618 10.8953L39.0126 16.5993H39.0129H41.2907C42.9989 16.4091 44.5175 15.8387 44.7071 12.6064C44.7071 11.4656 43.7582 10.8953 42.4295 10.8953H39.9618Z"
        fill="#081339"
      />
    </Root>
  )
}

export { LogoPhpCircle }
