import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoAnsible.styles'

interface Props extends PropsWithClassName {}

const LogoAnsible: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'logo-ansible-root'}
      width="708"
      height="870"
      viewBox="0 0 255.4 247.75"
      x="0px"
      y="0px"
      {...props}
    >
      <path
        d="M67.2,247.5c-0.5,0-1.1-0.1-1.5-0.7l-17.4-22.9v23.3h-4v-28.7c0-1.1,1-2.1,2-2.1c0.6,0,1.2,0.2,1.6,0.7
	L65.3,240v-23.2h4v28.7C69.3,246.7,68.3,247.5,67.2,247.5"
      />
      <path
        d="M100,247.8c-4,0-8.1-0.8-12-2.3l0.9-3.3c3.6,1.1,7.4,2,11,2c5,0,7.6-1.8,7.6-4.3c0-2.3-2-3.4-9-6.7
	c-7-3.3-9.7-5.4-9.7-9.5c0-5.2,4-7.3,11.4-7.3c3.1,0,7.9,0.6,10.6,1.5l-0.6,3.4c-3-0.7-6.8-1.2-9.9-1.2c-5.3,0-7.4,1-7.4,3.5
	s1.6,3.5,9,6.9c7.8,3.6,9.8,5.4,9.8,9.2C111.7,245.1,106.6,247.8,100,247.8"
      />
      <path d="M130.5,216.8h4.1v30.4h-4.1V216.8z" />
      <path
        d="M169.5,233.4h-10.2v10.3h10.2c3.5,0,5.6-2.2,5.6-5.3C175.1,235.6,172.9,233.4,169.5,233.4 M167.5,220.3h-8.2
	v9.7h8.2c3.3,0,5.7-2.1,5.7-5S171.1,220.3,167.5,220.3 M169.5,247.2h-12.2c-1.2,0-2.1-0.9-2.1-2.1v-26.3c0-1.1,0.9-2.1,2.1-2.1h10.1
	c6.2,0,9.9,3.1,9.9,7.9c0,2.6-1.5,5-3.9,6.4c3.5,1.1,5.8,3.9,5.8,7.4C179.3,243.4,175.3,247.2,169.5,247.2"
      />
      <path d="M198.9,247.2c-1.1,0-2-1-2-2.1v-28.4h4.1v26.8h16.3v3.7H198.9L198.9,247.2z" />
      <path
        d="M245.3,247.8c-8.4,0-13.4-3.1-13.4-9.4c0-3,1.7-5.8,4.9-7.1c-2.6-1.6-4-4-4-6.5c0-5.7,4.4-8.4,12.6-8.4
	c2.8,0,6.6,0.3,9.9,1.1l-0.4,3.4c-3.4-0.6-6.6-0.9-9.7-0.9c-5.3,0-8.1,1.5-8.1,5c0,2.8,2.6,5,6.1,5h7c1,0,1.8,0.8,1.8,1.7
	s-0.8,1.8-1.8,1.8h-7.6c-4,0-6.5,2-6.5,5c0,4.4,4.1,5.8,9.2,5.8c2.8,0,6.6-0.4,9.6-1.1l0.6,3.3C252.5,247.2,248.6,247.7,245.3,247.8
	"
      />
      <path
        d="M13.9,222.5l7.2,18l-10.8-8.7C10.2,231.8,13.9,222.5,13.9,222.5z M26.6,244.6l-11-27c-0.3-0.8-0.9-1.2-1.7-1.2
	s-1.4,0.4-1.8,1.2L0,247.2h4.1l4.8-12.2l14.3,11.7c0.6,0.5,1,0.7,1.5,0.7c1.1,0,2-0.8,2-2C26.8,245.3,26.7,245,26.6,244.6
	L26.6,244.6L26.6,244.6z"
      />
      <g>
        <polygon points="119.6,89 156.6,118.1 132.1,57.7 	" />
        <path
          d="M225,94.5C225,42.3,182.7,0,130.5,0C78.3,0,36,42.3,36,94.5S78.3,189,130.5,189
		C182.7,189,225.1,146.7,225,94.5L225,94.5z M169.3,141.6c-1.8,0-3.3-0.7-5.2-2.3l-48.8-39.4l-16.3,41H84.8l41.3-99.3
		c1.1-2.6,3.4-4,6-4s4.8,1.4,5.8,4l37.6,90.5l0,0c0.4,1.1,0.6,2.1,0.6,2.8C176.2,138.8,173,141.6,169.3,141.6z"
        />
      </g>
    </Root>
  )
}

export { LogoAnsible }
