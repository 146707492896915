import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoGitlab.styles'

interface Props extends PropsWithClassName {}

const LogoGitlab: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'logo-gitlab-root'}
      xmlns="http://www.w3.org/2000/svg"
      width="823.6"
      height="900"
      viewBox="0 0 850 700"
      {...props}
    >
      <path d="m626.81 171.44-.62-1.65-61.25-159.62c-1.24-3.09-3.51-5.77-6.39-7.63-7.42-4.74-17.53-2.68-22.27 4.95-.82 1.44-1.44 2.89-1.86 4.33l-41.25 126.42H325.92L284.67 11.81c-2.47-8.66-11.34-13.61-20-11.14-1.44.41-2.89 1.03-4.33 1.86-2.89 1.86-4.95 4.54-6.39 7.63l-61.04 159.62-.62 1.65c-18.15 47.23-2.68 100.85 37.74 131.37l.21.21.62.41 92.8 69.5 46.2 34.85 28.05 21.24c6.81 5.16 16.09 5.16 22.89 0l28.05-21.24 46.2-34.85 93.63-70.12.21-.21c40.63-30.32 55.89-83.94 37.95-131.16ZM141.43 604.56h37.6c-6.25-39.95-40.9-67.77-85.8-67.77C39.96 536.79 0 575.92 0 641.57s38.19 104.31 94.29 104.31c50.33 0 86.39-32.41 86.39-84.86v-24.4H98.06v28.76h46.91c-.59 28.99-19.92 47.38-50.56 47.38-34.06 0-57.4-25.58-57.4-71.66s23.69-71.19 56.57-71.19c24.51 0 41.37 13.08 47.85 34.65ZM205.43 743.04h35.95V590.53h-35.95v152.51Zm18.15-176.79c11.43 0 20.74-8.72 20.74-19.45s-9.31-19.56-20.74-19.56-20.86 8.72-20.86 19.56c0 10.84 9.31 19.45 20.86 19.45ZM349.1 590.53h-30.17v-36.65h-35.95v36.65h-21.69v27.81h21.69v84.86c-.24 28.76 20.63 42.9 47.73 42.08 7.19-.12 14.38-1.3 21.21-3.54l-6.01-28.17c-3.42.94-6.95 1.41-10.49 1.41-9.08 0-16.26-3.18-16.26-17.68v-78.97h30.17v-27.81h-.24ZM376.91 743.04h126.46v-30.88H413.8V539.61h-36.89v203.43ZM572.32 746.1c23.93 0 38.19-11.2 44.79-24.04h1.18v20.98h34.65V640.86c0-40.31-32.88-52.45-61.99-52.45-32.06 0-56.69 14.26-64.7 42.08l33.59 4.83c3.54-10.49 13.67-19.33 31.35-19.33 16.74 0 25.81 8.49 25.81 23.57v.59c0 10.37-10.84 10.84-37.71 13.67-29.58 3.18-57.87 12.02-57.87 46.44-.12 29.94 21.8 45.85 50.92 45.85Zm11.9-26.4c-14.97 0-25.69-6.84-25.69-20.04s12.02-19.56 28.05-21.92c9.43-1.3 28.29-3.65 33-7.43v18.03c0 16.97-13.79 31.35-35.36 31.35ZM679.46 743.04h35.36V719h2.12c5.66 11.08 17.44 26.75 43.73 26.75 35.95 0 62.94-28.52 62.94-78.85s-27.7-78.49-63.05-78.49c-26.87 0-38.19 16.15-43.61 27.23h-1.53V539.5h-35.95v203.54Zm35.24-76.25c0-29.7 12.73-48.79 35.83-48.79s36.3 20.39 36.3 48.79-12.49 49.5-36.3 49.5-35.83-19.92-35.83-49.5Z" />
    </Root>
  )
}

export { LogoGitlab }
