import React, { PropsWithChildren } from 'react'

import Col from '@Components/atoms/Col'
import { FontSizeEyebrow } from '@Components/atoms/Eyebrow/Eyebrow'
import LogoAnsible from '@Components/atoms/Logos/LogoAnsible'
import LogoApache from '@Components/atoms/Logos/LogoApache'
import LogoAws from '@Components/atoms/Logos/LogoAws'
import LogoDocker from '@Components/atoms/Logos/LogoDocker'
import LogoGit from '@Components/atoms/Logos/LogoGit'
import LogoGitlab from '@Components/atoms/Logos/LogoGitlab'
import LogoKubernetes from '@Components/atoms/Logos/LogoKubernetes'
import LogoMariaDb from '@Components/atoms/Logos/LogoMariaDb'
import LogoNextjs from '@Components/atoms/Logos/LogoNextjs'
import LogoPhp from '@Components/atoms/Logos/LogoPhp'
import LogoPhpCircle from '@Components/atoms/Logos/LogoPhpCircle'
import LogoReactjs from '@Components/atoms/Logos/LogoReactjs'
import LogoSulu from '@Components/atoms/Logos/LogoSulu'
import LogoSymfony from '@Components/atoms/Logos/LogoSymfony'
import LogoSymfonyNoText from '@Components/atoms/Logos/LogoSymfonyNoText'
import LogoTraefik from '@Components/atoms/Logos/LogoTraefik'
import LogoTypescript from '@Components/atoms/Logos/LogoTypescript'
import Row from '@Components/atoms/Row'
import { PropsWithClassName } from '@Components/helper'
import { Routes } from '@Components/Routes'

import {
  Root,
  StyledEyebrow,
  StyledLink,
  StyledLogosWrapper,
  StyledLogoWrapper,
} from './Technologies.styles'

export enum TechnologiesBackground {
  white = 'white',
  bluedeep = 'bluedeep',
  gray = 'gray',
}

export interface Technology {
  logo: JSX.Element
  text: string
  link?: Routes
}

export enum TechList {
  php = 'php',
  phpCircle = 'phpCircle',
  symfony = 'symfony',
  symfonyNoText = 'symfonyNoText',
  sulu = 'sulu',
  reactjs = 'reactjs',
  nextjs = 'nextjs',
  git = 'git',
  typescript = 'typescript',
  traefik = 'traefik',
  docker = 'docker',
  kubernetes = 'kubernetes',
  ansible = 'ansible',
  gitlab = 'gitlab',
  mariadb = 'mariadb',
  aws = 'aws',
  apache = 'apache',
}

interface Props extends PropsWithClassName {
  technologies: TechList[]
  background: TechnologiesBackground
  isHomePage?: boolean
}

export const TechLogo: {
  [key in keyof typeof TechList]: Technology
} = {
  php: { logo: <LogoPhp />, text: 'PHP' },
  phpCircle: { logo: <LogoPhpCircle />, text: 'PHP' },
  symfony: {
    logo: <LogoSymfony />,
    text: 'Symfony',
  },
  symfonyNoText: {
    logo: <LogoSymfonyNoText />,
    text: 'Symfony',
  },
  sulu: {
    logo: <LogoSulu />,
    text: 'Sulu',
  },
  reactjs: {
    logo: <LogoReactjs />,
    text: 'React JS',
  },
  nextjs: { logo: <LogoNextjs />, text: 'Next JS' },
  git: { logo: <LogoGit />, text: 'Git' },
  typescript: {
    logo: <LogoTypescript />,
    text: 'Typescript',
  },
  traefik: { logo: <LogoTraefik />, text: 'Traefik' },
  docker: { logo: <LogoDocker />, text: 'Docker' },
  kubernetes: { logo: <LogoKubernetes />, text: 'Kubernetes' },
  ansible: { logo: <LogoAnsible />, text: 'Ansible' },
  gitlab: { logo: <LogoGitlab />, text: 'Gitlab' },
  mariadb: { logo: <LogoMariaDb />, text: 'Maria DB' },
  apache: { logo: <LogoApache />, text: 'Apache HTTP Server' },
  aws: { logo: <LogoAws />, text: 'AWS' },
}

const Technologies: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { technologies, background } = props
  return (
    <Root
      background={background}
      className={props.className}
      data-testid={'technologies-root'}
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <StyledEyebrow background={background} size={FontSizeEyebrow.normal}>
            Technologien
          </StyledEyebrow>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <StyledLogosWrapper>
            <Row xsMiddle>
              {technologies.map((technology) => (
                <Col
                  key={'technology' + TechLogo[technology].text}
                  xs={3}
                  sm={2}
                  md={1}
                  lg={1}
                >
                  <StyledLogoWrapper>
                    {TechLogo[technology].link !== undefined && (
                      <StyledLink
                        href={TechLogo[technology].link || ''}
                        passHref
                        background={background}
                      >
                        {TechLogo[technology].logo}
                      </StyledLink>
                    )}
                    {!TechLogo[technology].link && TechLogo[technology].logo}
                  </StyledLogoWrapper>
                </Col>
              ))}
            </Row>
          </StyledLogosWrapper>
        </Col>
      </Row>
    </Root>
  )
}

export { Technologies }
