import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './LogoPhp.styles'

interface Props extends PropsWithClassName {}

const LogoPhp: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root
      className={props.className}
      data-testid={'logo-php-root'}
      width="100%"
      height="100%"
      viewBox="0 0 793 401"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M62.8043 84.3558H180.456C214.937 84.6418 239.945 94.6501 255.48 114.381C271.015 134.111 276.131 160.991 270.826 195.21C268.742 210.842 264.29 226.093 257.185 241.153C250.459 256.213 241.081 269.748 229.146 281.758C214.558 297.104 198.833 306.731 182.161 310.925C165.489 315.119 148.249 317.121 130.345 317.121H77.6765L61.0045 401H0L62.8043 84.3558ZM114.147 134.683L87.8124 267.174C89.6122 267.46 91.3173 267.651 93.1171 267.651H99.2744C127.314 267.937 150.806 265.173 169.467 259.263C188.224 253.068 200.728 231.621 207.169 194.829C212.474 163.946 207.169 146.121 191.35 141.356C175.814 136.685 156.395 134.397 132.997 134.779C129.492 135.065 126.082 135.255 122.862 135.255H113.673L114.147 134.683ZM340.261 0H400.792L383.646 84.3558H438.02C467.859 84.9277 490.12 91.1234 504.708 102.943C519.675 114.762 524.032 137.066 517.875 170.046L488.51 317.121H427.032L455.26 176.623C458.197 161.944 457.344 151.459 452.608 145.264C447.966 139.068 437.83 136.018 422.295 136.018L373.605 135.541L337.609 317.025H277.078L340.261 0ZM583.048 84.3558H700.605C735.085 84.6418 760.093 94.6501 775.629 114.381C791.164 134.111 796.279 160.991 790.975 195.21C788.891 210.842 784.438 226.093 777.334 241.153C770.608 256.213 761.23 269.748 749.294 281.758C734.706 297.104 718.982 306.731 702.31 310.925C685.638 315.024 668.397 317.121 650.494 317.121H597.825L581.153 401H520.149L583.048 84.3558ZM634.39 134.683L608.056 267.174C609.856 267.46 611.561 267.651 613.361 267.651H619.518C647.557 267.937 671.05 265.173 689.711 259.263C708.467 253.068 720.971 231.621 727.412 194.829C732.717 163.946 727.412 146.121 711.593 141.356C696.058 136.685 676.638 134.397 653.241 134.779C649.736 135.065 646.326 135.255 643.105 135.255H633.916L634.39 134.683Z"
        fill="black"
      />
    </Root>
  )
}

export { LogoPhp }
